import moment from "moment";
import { i18n } from '../language/i18n';

export const dateToIsoString = (date) => {
   return moment(date).format('YYYY-MM-DD');
}
export const dateToCustomFormat = (date, customFormat) => {
   return moment(date).format(customFormat);
}

export const dateToShortIsoString = (date) => {
   return moment(date).format('YYYY-MM');
}

export const addDays = (dateRaw, days) => {
   dateRaw.setDate(dateRaw.getDate() + days);
   return dateRaw;
};

export const getDaysBetween = (firstDate, secondDate) => {
   const differenceInMilliseconds = firstDate.getTime() - secondDate.getTime();
   const differenceInDays = Math.ceil(differenceInMilliseconds / (1000 * 3600 * 24));
   return differenceInDays
}

export const timestampToDate = (timestamp) => {
   const dateTime = new Date(timestamp.seconds * 1000);
   return dateTime
}

export const checkIfDatesAreEqual = (date1, date2) => {
   return moment(date1).format('YYYY-MM-DD') === moment(date2).format('YYYY-MM-DD');
}

export const getIsoDatesBetweenTwoDates = (startDate, endDate) => {
   startDate.setHours(12);
   endDate.setHours(12);
   const date = new Date(startDate.getTime());
   const dates = [];
   while (date <= endDate) {
      dates.push(new Date(date).toISOString().substr(0, 10));
      date.setDate(date.getDate() + 1);
   }
   return dates;
}

export const monthTextinRiga = (date) => {
   moment.locale(i18n.locale);
   return moment(date).format('MMMM YYYY');
}

export const addDaysToDate = (date, daysToAdd) => {
   date.setDate(date.getDate() + daysToAdd);
   return date;
}