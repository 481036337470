//SENIOR CODING 7 SC7 VERSION
const scversion = "SC7s 12.08.2024.1";

import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import { i18n } from "@/language/i18n";
import vuetify from "./plugins/vuetify";
import { createPinia, PiniaVuePlugin } from "pinia";

Vue.config.productionTip = false;

Vue.use(PiniaVuePlugin);
const pinia = createPinia();

import { Notifier } from "@airbrake/browser";
import { env } from "@/helpers/firebaseConfig.js";

var airbrake = new Notifier({
  environment: env,
  projectId: 314688,
  projectKey: "ef9313fb5a9312a193b489a99eb62552",
});

//only in production
if (process.env.NODE_ENV == "production") {
  Vue.config.errorHandler = function (err, vm, info) {
    if (err) {
      airbrake.notify({
        error: err,
        params: {
          info: info,
        },
      });
    }
  };
}

// FIREBASE
// Firebase App is always required and must be first
import firebase from "firebase/app";
// You don't need to import firebase/app either since it's being imported above
import "firebase/auth";
import "firebase/firestore";
import "firebase/performance";
import "firebase/analytics";
import "firebase/storage";
import "firebase/functions";
import { config } from "@/helpers/firebaseConfig.js";

firebase.initializeApp(config);

export const db = firebase.firestore();
export const auth = firebase.auth();
export const storage = firebase.storage();
export const analytics = firebase.analytics();
export const functions = firebase.functions();

//ENABLE OFFLINE
// firebase
//   .firestore()
//   .enablePersistence({ synchronizeTabs: true })
//   .then(() => {
//     console.log("offline persistence enabled");
//   })
//   .catch((err) => {
//     console.log("FIRESTORE OFFLINE FAILED", err);
//     if (err.code == "failed-precondition") {
//       // Multiple tabs open, persistence can only be enabled
//       // in one tab at a a time.
//       // ...
//     } else if (err.code == "unimplemented") {
//       // The current browser does not support all of the
//       // features required to enable persistence
//       // ...
//     }
//   });

// mixin
import { userDataMixin } from "@/mixins/userDataMixin";
Vue.mixin(userDataMixin);

let app: any;

firebase.auth().onAuthStateChanged((user) => {
  //uid for analytics
  user && analytics.setUserId(user.uid);

  if (!app) {
    // start app
    app = new Vue({
      vuetify,
      router,
      pinia,
      i18n,
      created() {
        if (!user) {
          let path = window.location.pathname;
          if (path != "/login") {
            this.$router.push("/login");
          }
        } else {
          //add user name and sc version to airbrake
          airbrake.addFilter((notice) => {
            notice.context.appUserId = user.uid;
            notice.context.appUserEmail = user.email;
            notice.context.appUser = user.displayName;
            notice.context.appVersion = scversion;
            return notice;
          });

          //AUTH PERSISTANCE SESSION - LOG OUT AFTER CLOSING WINDOW
          firebase
            .auth()
            .setPersistence(firebase.auth.Auth.Persistence.SESSION)
            .then()
            .catch((error) => {
              console.log("FIREBASE AUTH SESSION ERROR", error);
            });
        }
      },
      data: {
        currentUserData: {},
        manager: false,
        admin: false,
        aider: false,
        sudo: false,
        blocked: false,
        scversion: scversion,
        mainSaving: false,
      },

      render: (h) => h(App),
    }).$mount("#app");
  }
});
