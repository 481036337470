var dayjs = require("dayjs");
var utc = require("dayjs/plugin/utc");
dayjs.extend(utc);
var timezone = require("dayjs/plugin/timezone"); // dependent on utc plugin
dayjs.extend(timezone);

export const formatDateInRiga = (date) => {
  const currentRigaTime = dayjs(date).tz("Europe/Riga");
  return currentRigaTime.format("DD.MM.YYYY.");
};

export const dayInRiga = function () {
  const currentRigaTime = dayjs().tz('Europe/Riga');
  return currentRigaTime.day();
};
export const timeInRiga = function () {
  const currentRigaTime = dayjs().tz('Europe/Riga');
  return { hours: currentRigaTime.hour(), minutes: currentRigaTime.minute() };
};

export const isoDateInRiga = (date) => {
  const currentRigaTime = dayjs(date).tz("Europe/Riga");
  return currentRigaTime.format("YYYY-MM-DD");
};

export const monthKeyInRiga = (date) => {
  const currentRigaTime = dayjs(date).tz("Europe/Riga");
  return currentRigaTime.format("YYYY-MM");
};

export const formatDateTimeInRiga = (date) => {
  const currentRigaTime = dayjs(date).tz("Europe/Riga");
  return currentRigaTime.format("DD.MM.YYYY. HH:mm:ss");
};

export const subtractDaysIsoToIso = (isoDate, days) => {
  let d = dayjs(isoDate).utc();
  let prevD = d.subtract(days, "d");
  return prevD.toISOString().substring(0, 10);
};
