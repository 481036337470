import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import MyTasks from "../views/mytasks/ListMyTasksToday.vue";

import { auth, analytics } from "@/main";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/admin/login.vue"),
  },
  {
    path: "/",
    name: "mytasks",
    component: () => import("../views/mytasks/MyTasksNew.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/clients",
    name: "clients",
    component: () => import("../views/myclients/MyClients.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/calendar",
    name: "calendar",
    component: () => import("../views/calendar/calendar.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/helpvideoadl",
    name: "helpvideoadl",
    component: () => import("../views/helpvideos/helpVideoADL.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/helpemergency",
    name: "helpemergency",
    component: () => import("../views/helpvideos/helpEmergency.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/helpdescriptions",
    name: "helpdescriptions",
    component: () => import("../views/helpvideos/helpDescriptions.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/tutorials/:tutorialId",
    name: "tutorials",
    component: () => import("../views/tutorials/Tutorial.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/tutorials/:tutorialId/presentationFrame",
    name: "presentationFrame",
    component: () => import("../views/tutorials/PresentationIframe.vue"),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/tests/:testId",
    name: "tests",
    component: () => import("../views/tutorials/Test.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/worker-suggestions-register",
    name: "workerSuggestionRegister",
    component: () => import("../views/workerSuggestions/workerSuggestions.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/add-or-edit-worker-suggestion/:suggestionId",
    name: "addOrEditWorkerSuggestion",
    component: () =>
      import("../views/workerSuggestions/addOrEditWorkerSuggestion.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/contacts",
    name: "contacts",
    component: () => import("../views/contacts/Contacts.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/yourtaxes",
    name: "yourtaxes",
    component: () => import("../views/taxes/Taxes.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/politenessPhrases",
    name: "politenessPhrases",
    component: () => import("../views/politenessPhrases/PolitenessPhrases.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/employeeInformation",
    name: "employeeInformation",
    component: () =>
      import("../views/employeeInformation/EmployeeInformation.vue"),
    meta: {
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: routes,
});

router.beforeEach((to, from, next) => {
  const currentUser = auth.currentUser;
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  // tslint:disable-next-line:max-line-length
  if (requiresAuth && !currentUser) {
    const loginpath = window.location.pathname;

    next({ name: "login", query: { from: loginpath } });
  } else if (!requiresAuth && currentUser) {
    next("mytasks");
  } else {
    if (currentUser) {
      const user = currentUser.displayName || "n/a";
      const event = "to_page_" + to.name;
      //log movement
      analytics.logEvent(event, {
        user: user,
      });
    }
    next();
  }
});

export default router;
