import Vue from 'vue';
import VueI18n from 'vue-i18n';
//import { messages } from './lang';

Vue.use(VueI18n);

//https://phrase.com/blog/posts/ultimate-guide-to-vue-localization-with-vue-i18n/#Getting_the_Active_Locale
function loadLocaleMessages() {
  const locales = require.context(
    '../language/locales',
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  var messages: { [key: string]: any } = {};
  locales.keys().forEach(k => {
    const matched = k.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(k);
    }
  });
  return messages;
}

function getBrowserLocale(options = {}) {
  //check local storage
  let savedLocale = localStorage.getItem('sc7-locale');
  if (savedLocale !== null) {
    return JSON.parse(savedLocale);
  }

  const defaultOptions = { countryCodeOnly: false };
  const opt = { ...defaultOptions, ...options };
  const navigatorLocale =
    navigator.languages !== undefined
      ? navigator.languages[0]
      : navigator.language;

  if (!navigatorLocale) {
    return undefined;
  }

  const trimmedLocale = opt.countryCodeOnly
    ? navigatorLocale.trim().split(/-|_/)[0]
    : navigatorLocale.trim();
  return trimmedLocale;
}

export let i18n = new VueI18n({
  locale: getBrowserLocale({ countryCodeOnly: true }), // set locale
  fallbackLocale: 'lv', // set fallback locale
  silentFallbackWarn: true, //conslole warning fix https://kazupon.github.io/vue-i18n/guide/fallback.html#fallback-interpolation
  messages: loadLocaleMessages(), // set locale messages
});
